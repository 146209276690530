<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Role</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">Forms</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="roleUpdate" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    required
                  />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Payroll Point</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.point_for_payroll"
                    required
                  />
                  <small class="text-danger" v-if="errors.point_for_payroll">{{
                    errors.point_for_payroll[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary mr-1" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "role",
  data() {
    return {
      name: "Edit Role",
      form: {
        name: "",
        point_for_payroll: "",
      },
      errors: {},
    };
  },
  beforeMount() {
    let id = this.$route.params.id;
    axios
      .get(this.$store.state.api + "role/" + id, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
      .then((res) => {
        this.form.name = res.data.data.role.name;
        this.form.point_for_payroll = res.data.data.role.point_for_payroll;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    roleUpdate() {
      let id = this.$route.params.id;
      let formData = new FormData();
      formData.set("name", this.form.name);
      formData.set("point_for_payroll", this.form.point_for_payroll);

      axios
        .post(this.$store.state.api + "role/update/" + id, formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your role has been updated!");
          this.$router.push({ name: "Role" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          console.log(error.response.data);
        });
    },
  },
};
</script>
